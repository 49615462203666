import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CannySDK from 'common/CannySDK';
import ExperimentContainer from 'common/containers/ExperimentContainer';
import HighlightContainer from 'common/containers/HighlightContainer';
import IntercomContainer from 'common/containers/IntercomContainer';
import MixpanelContainer from 'common/containers/MixpanelContainer';
import ModalContainer from 'common/containers/ModalContainer';
import RouterContainer from 'common/containers/RouterContainer';
import ThemeContainer from 'common/containers/ThemeContainer';
import ViewerContainer from 'common/containers/ViewerContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import Helmet from 'common/helmets/Helmet';

import DevelopersCompanyContainer from './DevelopersCompanyContainer';
import DevelopersNav from './DevelopersNav';

import 'css/components/developers/_DevelopersContainer.scss';

import FaviconURL from 'img-raw/logo-icon.png';

CannySDK.init();

class DevelopersContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  state = {
    companyID: null,
  };

  onCompanyChange = (company) => {
    this.setState({
      companyID: company._id,
    });
  };

  render() {
    const { companyID } = this.state;
    const { children } = this.props;
    return (
      <div>
        <ThemeContainer configTheme="auto">
          <RouterContainer
            location={this.props.location}
            params={this.props.params}
            router={this.props.router}>
            <ViewerContainer>
              <DevelopersCompanyContainer companyID={companyID}>
                <IntercomContainer>
                  <ModalContainer>
                    <ExperimentContainer>
                      <MixpanelContainer property="developers" trackPageViews={true}>
                        <HighlightContainer>
                          <div className="developersContainer">
                            <Helmet
                              link={[
                                {
                                  href: FaviconURL,
                                  rel: 'shortcut icon',
                                },
                              ]}
                              title="Canny Developer Hub"
                            />
                            <DevelopersNav onCompanyChange={this.onCompanyChange} />
                            {cloneElementWithProps(children, {
                              key: companyID || 'no company',
                            })}
                          </div>
                        </HighlightContainer>
                      </MixpanelContainer>
                    </ExperimentContainer>
                  </ModalContainer>
                </IntercomContainer>
              </DevelopersCompanyContainer>
            </ViewerContainer>
          </RouterContainer>
        </ThemeContainer>
      </div>
    );
  }
}

export default asyncConnect([
  ViewerContainer.createAsyncConnect({
    includeDocsData: true,
  }),
])(DevelopersContainer);
