import React from 'react';

import { IndexRedirect, IndexRoute, Route } from 'react-router';

import DevelopersContainer from 'common/developers/DevelopersContainer';
import SDKDocs from 'common/developers/docs/SDKDocs';
import { createDynamicImport } from 'common/util/dynamicImport';

export default (importTracker) => {
  const dynamicImport = createDynamicImport(importTracker, 'developers');
  return (
    <Route path="/" component={DevelopersContainer}>
      <IndexRedirect to="install" />
      <Route path="install" component={SDKDocs}>
        <IndexRoute getComponent={dynamicImport('InstallDocs')} />
        <Route path="advanced" getComponent={dynamicImport('IdentifyAdvancedDocs')} />
        <Route path="companies" getComponent={dynamicImport('CompaniesDocs')} />
        <Route path="custom-fields" getComponent={dynamicImport('CustomFieldsDocs')} />

        <Route path="widget">
          <IndexRoute getComponent={dynamicImport('WidgetDocsHome')} />
          <Route path="web" getComponent={dynamicImport('WidgetDocsWeb')} />
          <Route path="mobile" getComponent={dynamicImport('WidgetDocsMobile')} />
          <Route path="sso" getComponent={dynamicImport('WidgetDocsSSO')} />
          <Route path="advanced" getComponent={dynamicImport('WidgetDocsAdvanced')} />
        </Route>

        <Route path="changelog">
          <IndexRoute getComponent={dynamicImport('ChangelogDocs')} />
          <Route
            path="customizations"
            getComponent={dynamicImport('ChangelogCustomizationsDocs')}
          />
          <Route path="privacy" getComponent={dynamicImport('ChangelogPrivacyDocs')} />
        </Route>
      </Route>

      <Route path="api-reference" getComponent={dynamicImport('APIDocs')} />

      {/* 404 */}
      <Route path="*" getComponent={dynamicImport('FourOhFour', { app: 'general' })} />
    </Route>
  );
};
