import React, { Component } from 'react';

import { hot } from 'react-hot-loader/root';

import CannyRouter from 'common/core/CannyRouter';

import DevelopersRoutes from './DevelopersRoutes';

import 'css/developers.scss';

class DevelopersApp extends Component {
  render() {
    const { history } = this.props;

    return <CannyRouter history={history} routes={DevelopersRoutes()} />;
  }
}

export default hot(DevelopersApp);
