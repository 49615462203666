import React, { Component } from 'react';

import Link from 'common/Link';
import UppercaseHeader from 'common/UppercaseHeader';

import 'css/components/developers/docs/sdk/_SDKDocsSidebar.scss';

export default class SDKDocsSidebar extends Component {
  render() {
    return (
      <div className="sdkDocsSidebar card">
        <div className="section">
          <UppercaseHeader>Setup</UppercaseHeader>
          <div className="links">
            <Link to="/install" activeClassName="active">
              Install Canny
            </Link>
            <Link to="/install/companies" activeClassName="active">
              Add Company Data
            </Link>
            <Link to="/install/custom-fields" activeClassName="active">
              Add Custom Fields
            </Link>
            <Link to="/install/advanced" activeClassName="active">
              Advanced
            </Link>
          </div>
        </div>
        <div className="section">
          <UppercaseHeader>Feedback Widget</UppercaseHeader>
          <div className="links">
            <Link to="/install/widget" activeClassName="active">
              Overview
            </Link>
            <Link to="/install/widget/web" activeClassName="active">
              Web
            </Link>
            <Link to="/install/widget/mobile" activeClassName="active">
              Mobile
            </Link>
            <Link to="/install/widget/sso" activeClassName="active">
              Single Sign-On
            </Link>
            <Link to="/install/widget/advanced" activeClassName="active">
              Advanced
            </Link>
          </div>
        </div>
        <div className="section">
          <UppercaseHeader>Changelog Widget</UppercaseHeader>
          <div className="links">
            <Link to="/install/changelog" activeClassName="active">
              Setup
            </Link>
            <Link to="/install/changelog/customizations" activeClassName="active">
              Customizations
            </Link>
            <Link to="/install/changelog/privacy" activeClassName="active">
              Privacy
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
