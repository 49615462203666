import React, { Component } from 'react';

import ContentContainer from 'common/containers/ContentContainer';

import SDKDocsSidebar from './sdk/SDKDocsSidebar';

import 'css/components/developers/docs/_SDKDocs.scss';

export default class SDKDocs extends Component {
  render() {
    return (
      <ContentContainer outerClassName="sdkDocs" innerClassName="sdkDocsContents">
        <SDKDocsSidebar />
        <div className="mainContainer card">{this.props.children}</div>
      </ContentContainer>
    );
  }
}
