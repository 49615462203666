import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LocationContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Dropdown from 'common/Dropdown';
import Link from 'common/Link';
import NavLogo from 'common/NavLogo';
import UppercaseHeader from 'common/UppercaseHeader';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import 'css/components/developers/_DevelopersNav.scss';

class DevelopersNav extends Component {
  static propTypes = {
    location: PropTypes.object,
    onCompanyChange: PropTypes.func,
    viewer: PropTypes.shape({
      companies: PropTypes.array,
    }),
  };

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer || !viewer.companies || !viewer.companies.length) {
      return;
    }

    const { onCompanyChange } = this.props;
    onCompanyChange(viewer.companies[0]);
  }

  onCompanyChange = (companyID) => {
    const {
      viewer: { companies },
    } = this.props;
    const company = companies.find((company) => {
      return company._id === companyID;
    });
    this.props.onCompanyChange(company);
  };

  renderCompanyDropdown() {
    const { viewer } = this.props;
    if (!viewer || viewer.loggedOut || !viewer.companies || viewer.companies.length === 0) {
      return null;
    }

    const { companies } = viewer;
    return (
      <div className="companyDropdown">
        <div className="label">Fill docs for:</div>
        <Dropdown
          defaultSelectedName={companies[0]._id}
          onChange={this.onCompanyChange}
          options={companies.map((company) => ({
            name: company._id,
            render: company.name + ' (' + company.subdomain + '.canny.io)',
          }))}
        />
      </div>
    );
  }

  renderLoginLink() {
    const { viewer } = this.props;
    if (viewer && !viewer.loggedOut && viewer._id) {
      return null;
    }

    const {
      location: { pathname },
    } = this.props;
    const currentURL = devURL('https://developers.canny.io' + pathname);
    const loginURL = devURL('https://canny.io/login?redirect=' + encodeURIComponent(currentURL));
    return (
      <a className="link" href={loginURL}>
        <UppercaseHeader>Log&nbsp;in</UppercaseHeader>
      </a>
    );
  }

  render() {
    const {
      location: { pathname },
    } = this.props;
    const apiClassName = pathname.startsWith('/api-reference') ? 'active' : '';
    const installClassName = pathname.startsWith('/install') ? 'active' : '';
    return (
      <div className="developersNav">
        <div className="leftContainer">
          <NavLogo />
          <span className="developerHub">Docs</span>
          <div className="links">
            <Link className={installClassName} to="/install">
              <UppercaseHeader>Install</UppercaseHeader>
            </Link>
            <Link className={apiClassName} to="/api-reference">
              <UppercaseHeader>API</UppercaseHeader>
            </Link>
          </div>
        </div>
        <div className="rightContainer">
          {this.renderLoginLink()}
          {this.renderCompanyDropdown()}
        </div>
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  viewer: ViewerContext,
})(DevelopersNav);
