import React, { useContext } from 'react';

import CompanyContainer from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';

import type { Viewer } from 'common/api/endpoints/viewer';

type Props = {
  children: React.ReactNode;
  companyID?: string;
};

const DevelopersCompanyContainer = ({ companyID, children }: Props) => {
  const viewer = useContext<Viewer>(ViewerContext);

  let company;
  if (viewer && companyID) {
    company = viewer.companies?.find((company) => {
      return company._id === companyID;
    });
  }

  return <CompanyContainer company={company}>{children}</CompanyContainer>;
};

export default DevelopersCompanyContainer;
