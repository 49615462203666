import createEntry from 'common/core/createEntry';

import DevelopersApp from './DevelopersApp';
import DevelopersReducers from './DevelopersReducers';

const GA4MeasurementID = 'G-G7SEFLK6RB';
const SentryDSN = 'a5472463ab7d42fbade675a4a4559245';

createEntry({
  app: DevelopersApp,
  ga4MeasurementID: GA4MeasurementID,
  reducers: DevelopersReducers,
  sentryDSN: SentryDSN,
});
