import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import cookies from '../reducers/cookies';
import viewer from '../reducers/viewer';

export default combineReducers({
  cookies,
  reduxAsyncConnect,
  routing: routerReducer,
  viewer,
});
